import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointXl, sbkCoreBreakpointMd, sbkCoreBreakpointLg} from "@/tokens/variables";

const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);
const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
export const sbkNavFlyoutStyle = css`
    :host {
        --nav-flyout-list-width: calc((100% / 12) * 8);
    }

    .contact-flyout {
        display: none;
        position: absolute;
        width: 100%;
        z-index: 10;
        left: unset;
        right: 0;
        top: var(--sbkTopNavBarSizingLargeScreenFixedBarHeight);
        background-color: var(--sbkNavFlyoutColorBgColorOnScroll);
        height: calc(100vh - var(--sbkTopNavBarSizingLargeScreenFixedBarHeight));
        overflow-x: hidden;
        overflow-y: auto;

        @media all and (min-width: ${breakpointMd}) {
            width: var(--sbkNavFlyoutSizingMdFixedWidth);
        }
    }

    .contact-flyout__content-wrapper {
        padding: var(--sbkNavFlyoutSpacingViewportXlInsetTop) var(--sbkCoreGridSmMargin) var(--sbkNavFlyoutSpacingViewportXlInsetBottom);
        @media all and (min-width: ${breakpointMd}) {
            padding: var(--sbkNavFlyoutSpacingViewportXlInsetTop) var(--sbkCoreGridMdMargin) var(--sbkNavFlyoutSpacingViewportXlInsetBottom);
        }

        @media all and (min-width: ${breakpointLg}) {
            padding: var(--sbkNavFlyoutSpacingViewportXlInsetTop) var(--sbkCoreGridLgMargin) var(--sbkNavFlyoutSpacingViewportXlInsetBottom);
        }
    }

    .contact-flyout.expanded {
        display: flex;
        flex-direction: column;
    }

    .hide {
        display: none
    }

    .contact-flyout-backdrop {
        display: none;
    }

    .contact-flyout-backdrop.expanded {
        display: block;
        position: absolute;
        top: 102px;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 9;
        background-color: var(--sbkNavFlyoutColorBackdrop);

        @media all and (max-width: ${breakpointXl}) {
            top: var(--sbkTopNavBarSizingSmallScreenFixedBarHeight)
        }
    }
`;
