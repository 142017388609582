import {customElement, state} from 'lit/decorators.js';
import {sbkNavFlyoutStyle} from './contact-flyout.styles';
import {html, LitElement } from 'lit';
import {SbkLink} from "@/components/link/link";

@customElement('sbk-contact-flyout')
export class SbkContactFlyout extends LitElement {

    @state()
    expanded = false;

    @state()
    hide = false;

    static get styles() {
        return sbkNavFlyoutStyle;
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener('keydown', this._handleKeyDown);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        document.removeEventListener('keydown', this._handleKeyDown);
    }

    private _handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape' && this.expanded) {
            this._handleTriggerClick(event)
        }
    };

    render() {
        return html`
            <slot name="trigger" @click=${this._handleTriggerClick}></slot>
            <div class="contact-flyout ${this.expanded ? 'expanded' : ''}">
                <div class="contact-flyout__content-wrapper">
                    <slot></slot>
                </div>
            </div>
            <div class="contact-flyout-backdrop ${this.expanded ? 'expanded' : ''}"
                 @click=${this._handleTriggerClick}></div>
        `;
    }

    _handleTriggerClick(event: Event) {
        event.preventDefault()
        this.expanded = !this.expanded
        if (this.expanded) {
            document.body.classList.add('menu-open')
        } else {
            document.body.classList.remove('menu-open')
        }
        this._changeTriggerElement()
        this.hide = false
        const triggeredFlyout = new CustomEvent('triggeredContactFlyout', {
            bubbles: true,
            composed: true,
            detail: {
                id: this.id,
                expanded: this.expanded
            }
        })
        this.dispatchEvent(triggeredFlyout)
    }

    _changeTriggerElement() {
        const link = this.querySelector('sbk-link[slot="trigger"]') as SbkLink
        if (link) {
            link.setAttribute('aria-expanded', String(this.expanded))
            if (this.expanded && link.variant === 'icon-navigation') {
                link.icon = 'close-round'
                link.isSelected = true
            } else if (link.variant === 'icon-navigation') {
                link.icon = 'chat'
                link.isSelected = false
            }
        }
    }
}
