import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointLg, sbkCoreBreakpointMd, sbkCoreBreakpointXl} from "@/tokens/variables";

const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);

export const sbkMainNavStyle = css`
    :host {
        --main-nav__bg: var(--_main-nav__bg, var(--sbkMainNavBarColorBgColorDefault));
        --main-nav__bg-scroll: var(--_main-nav__bg-scroll, var(--sbkTopNavBarBgColorOnLightDefault));
        --main-nav__fg: var(--_main-nav__fg, var(--sbkTopNavBarFgColorOnLightEnabled));
        --main-nav-items-width: calc((100% / 12) * 10);
        --main-nav-logo-width: calc((100% / 12) * 2);
        position: relative;
    }

    #sensor {
        position: absolute;
        width: 1px;
        height: calc(var(--sbkMainNavBarSpacingLargeScreenPaddingTopBotom) + var(--sbkMainNavBarSpacingLargeScreenPaddingTopBotom) + var(--sbkTopNavBarSizingSmallScreenFixedBarHeight));
    }

    .main-nav {
        display: flex;
        flex-wrap: wrap;
        background-color: var(--main-nav__bg);
        padding: var(--sbkMainNavBarSpacingLargeScreenPaddingTopBotom) 0;
        position: sticky;
        top: -1px;
        z-index: 10;
        justify-content: space-around;

        @media all and (max-width: ${breakpointXl}) {
            background-color: var(--sbkTopNavBarBgColorOnLightDefault);
            margin: 0;
            padding: 0;
            height: var(--sbkTopNavBarSizingSmallScreenFixedBarHeight);
        }

        &.not-sticky {
            position: relative;
            top: unset;
        }
    }

    .main-nav--muted {
        @media all and (min-width: ${breakpointXl}) {
            background-color: var(--sbkMainNavBarColorBgColorOnClick);
        }
    }

    .main-nav__wrapper {
        display: flex;
        width: 100%;
        max-width: var(--sbkCoreContentAreaMaxWidth);
        margin: 0;
        padding: 0 var(--sbkCoreGridSmMargin);
        justify-content: space-between;

        @media all and (min-width: ${breakpointMd}) {
            padding: 0 var(--sbkCoreGridMdMargin);
        }

        @media all and (min-width: ${breakpointLg}) {
            padding: 0 var(--sbkCoreGridLgMargin);
        }

        @media all and (min-width: ${breakpointXl}) {
            margin: 0 auto;
            padding: 0 var(--sbkCoreGridXlMargin);
        }
    }

    .main-nav.active {
        padding: var(--sbkMainNavBarSpacingLargeScreenPaddingTopBotom) 0;
        @media all and (max-width: ${breakpointXl}) {
            background-color: var(--sbkTopNavBarBgColorOnLightDefault);
            padding: 0;
        }
    }

    .main-nav.active.is-pinned {
        padding: 0;
    }

    .logo__wrapper {
        display: flex;
        width: var(--main-nav-logo-width);
        @media all and (max-width: ${breakpointXl}) {
            display: flex;
            align-items: center;
        }
    }

    .logo__wrapper .logo {
        width: 137.33px;
        height: var(--sbkMainNavBarSizingLargeScreenLogoHeight);
        @media all and (max-width: ${breakpointXl}) {
            width: 68.66px;
            height: var(--sbkTopNavBarSizingLogoHeight);
        }
    }

    .main-nav-items__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: flex-end;
        width: var(--main-nav-items-width);

        @media all and (max-width: ${breakpointXl}) {
            justify-content: flex-end;
        }
    }

    .main-nav-items {
        flex-grow: 1;
        @media all and (max-width: ${breakpointXl}) {
            display: none;
        }
    }

    .icon-nav-items {
        display: none;

        @media all and (max-width: ${breakpointXl}) {
            display: flex;
            align-items: center;
            height: var(--sbkTopNavBarSizingLargeScreenFixedBarHeight);
        }
    }

    .cta-nav-items__wrapper {
        display: none;
    }

    .main-nav.is-pinned .logo__wrapper {
        display: none;

        @media all and (max-width: ${breakpointXl}) {
            display: flex;
        }
    }

    .main-nav.is-pinned {
        z-index: 10;
        background-color: var(--main-nav__bg-scroll);
        height: var(--sbkTopNavBarSizingLargeScreenFixedBarHeight);
        padding: 0;
        transition: padding-top 0.5s;

        .main-nav-items__wrapper {
            width: unset;
        }
    }

    .main-nav.is-pinned .main-nav-items__wrapper {
        flex-grow: unset;
    }

    .main-nav.is-pinned .cta-nav-items__wrapper {
        display: flex;
        align-items: flex-end;
        @media all and (max-width: ${breakpointXl}) {
            display: none;
        }
    }

    .main-nav.is-pinned .icon-nav-items {
        display: flex;
        height: 36px;

        @media all and (max-width: ${breakpointXl}) {
            align-items: center;
            height: var(--sbkTopNavBarSizingLargeScreenFixedBarHeight)
        }
    }

    .verticalDivider {
        margin-left: var(--sbkTopNavBarSpacingLargeScreenGap);
        margin-right: var(--sbkTopNavBarSpacingLargeScreenGap);
        border-left: var(--sbkTopNavBarBorderRadiusDivider) solid var(--main-nav__fg);
        height: var(--sbkTopNavBarSizingDividerHeight);
        margin-top: 2px;
        @media all and (max-width: ${breakpointXl}) {
            display: none;
        }
    }
`;
